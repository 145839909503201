<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：换仓库出仓</strong>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <el-col :lg="4" :md="6" :sm="8">
                                <el-tooltip class="item" effect="dark"
                                            :content="`创建日期:${formData.creat_date_warehouse_bill}`" placement="bottom-end" :open-delay = "750">
                                    <el-form-item label="单号:">
                                        <el-input @keyup.native.enter="$_focusNext('only_bill_date')"
                                                  v-model="formData.bill_id"
                                                  :readonly="true" placeholder="自动生成" size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="4" :md="6" :sm="8">
                                <el-form-item label="日期:">
                                    <el-date-picker
                                            ref="only_bill_date"
                                            v-model="formData.only_bill_date"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            @blur="$_focusNext('handled_by')"
                                            style="width:170px"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="经手人:">
                                    <el-select ref="handled_by" v-model="formData.handled_by" clearable placeholder="可搜索查询"
                                               size="mini"
                                               default-first-option
                                               :filter-method="$_handledByListFilter"
                                               @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('remark_warehouse_bill')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getHandledByList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" @keyup.native.enter="$_focusNext('remark_warehouse_bill')"
                                              v-model="formData.registrar" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :md="6" :sm="8">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_warehouse_bill"
                                              @keyup.native.enter="$_focusNext('last_name_warehouse_bill')"
                                              v-model="formData.last_date_warehouse_bill" placeholder="自动生成"
                                              :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_warehouse_bill"
                                              @keyup.native.enter="$_focusNext('remark_warehouse_bill')"
                                              v-model="formData.last_name_warehouse_bill" placeholder="自动生成"
                                              :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="5" :md="6" :sm="8">
                                <el-form-item label="备注 :">
                                    <el-input ref="remark_warehouse_bill" @keyup.native.enter="$_focusNext('consignee')"
                                              v-model="formData.remark_warehouse_bill" placeholder="请输入"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="收货人:">
                                    <el-input ref="consignee" v-model="formData.consignee"
                                              @keyup.native.enter="$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="合计数量:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="总理论重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :md="6" :sm="8">
                                <el-form-item label="总过磅重:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_weighing_heavy"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="6" :md="12">
                                <vxe-toolbar style=" margin-left: 12px;"  class="pull-left" custom export ref="xToolbar2">
                                </vxe-toolbar>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-left: 12px;margin-top: 12px">返回
                                </el-button>
                                <el-button style=" margin-top: 12px; margin-left: 12px;" type="success"
                                           round @click="$router.push('/replaceOutWarehouse')" size="mini">新建
                                </el-button>

                                <el-button style=" margin-top: 12px; " id="createBill" type="danger" plain round
                                           :disabled="formData.is_cancel"
                                           @click="onSubmit" size="mini">保存
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :lg="24">
                        <MnGrid ref="xGrid" :height="(this.getViewHeight() - 200)+''" :table-column="tableColumn" :table-data-main="tableDataMain"
                                :form-data="formData" :allow-create="false" search-type="ReplaceWarehouseBill" :search-all="false"></MnGrid>
                    </el-col>
                </el-row>


            </el-form>
        </el-main>
    </div>
</template>

<script>

    import MnGrid from "../../../components/MnGrid";

    export default {
        components: {MnGrid},
        data() {
            return {
                isAddBill: true,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq', title: '序号', className: 'unEditor', width: 40},
                    {
                        field:'specification',
                        width: 150, title: '实际规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },

                    {field:'category', width: 50, className: 'unEditor', title: '类别'},
                    {field:'warehouse_out', width: 65, className: 'unEditor', title: '调出仓库'},

                    {
                        field:'warehouse',
                        width: 65,
                        title: '调入仓库',
                        slots: {edit: 'warehouse_in_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{
                        field:'single_count', width: 55, title: '支数',
                        slots: {edit: 'single_count_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },*/
                    {
                        field:'single_count', width: 55, title: '支数',
                        slots: {edit: 'single_count_autocomplete_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                    },
                    {
                        field:'batch_number', visible: true,
                        width: 65,
                        className: 'unEditor',
                        title: '批号'
                   },
                   /* slots: {edit: 'weighing_heavy_edit'},
                   editRender: {autofocus: 'input.el-input__inner'}
                    */
                    {
                        field:'weighing_heavy', width: 74, title: '过磅重(吨)',
                        titleHelp: {message: '过磅重=理论支重 * 支数\n可以自行修改\n(吨)'},
                        className: 'unEditor',
                   },
                    {
                        field:'remark_stock',
                        width: 120,
                        title: '产品备注',
                        editRender: {name: 'input', props: {controls: false}}
                   },
                    {field:'unit', width: 60, className: 'unEditor', title: '单位'},
                    {field:'total_count', width: 50, className: 'unEditor', title: '库存'},
                    {field:'warehouse4', width: 50, className: 'unEditor', title: '厂一'},
                    {field:'warehouse5', width: 50, className: 'unEditor', title: '厂二'},
                    {field:'warehouse1', width: 50, className: 'unEditor', title: '仓一'},
                    {field:'warehouse2', width: 50, className: 'unEditor', title: '仓二'},
                    {field:'warehouse3', width: 50, className: 'unEditor', title: '仓三'},
                    {field:'warehouse9', width: 50, className: 'unEditor', title: '仓四'},
                    {field:'warehouse7', width: 50, className: 'unEditor', title: '新门市'},
                    {
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },

                    {
                        field:'theoretical_weight_single',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'},
                        title: '理论支重'
                   },
                    {
                        field:'the_theory_of_count',
                        visible: false,
                        className: 'unEditor',
                        titleHelp: {message: '理论支数=过磅重 ÷ 每支理论重量'},
                        title: '理论支数'
                   },
                    {
                        field:'single_weight',
                        visible: false,
                        className: 'unEditor',
                        titleHelp: {message: '支重=过磅重/支数'},
                        title: '支重(kg)'
                   },{
                        field:'warehouse_id',visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调入仓库Id'
                   },{
                        field:'warehouse_out_id',visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调出仓库Id'
                   },
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}},
                ],
                formData: {
                    only_bill_date: '',
                    creat_date_warehouse_bill : '',
                    bill_id: '',
                    bill_id_type: 'HC',
                    handled_by: '',
                    registrar: '',
                    consignee: '',
                    last_date_warehouse_bill: '',
                    last_name_warehouse_bill: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    remark_warehouse_bill: '',
                    total_number: '',
                    total_theoretical_weight: '',
                    total_amount: '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//税金
                    bill_type: '',
                    is_cancel: false,
                    is_delete : false,
               },
                tableDataMain: [],
                unitList: [],
                categoryList: [],
                specification: [],
                activeName: 'first',
                supplierList: [],
                warehouseList: [],
                billTypeList: [],
                accountList: [],
           }
       },
        methods: {
            //查询账户
            searchAccountList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountList',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.accountList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            //查询仓库
            searcWarehouseList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseSetting/searchWarehouse',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.warehouseList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            //查询规格
            searchUnitList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/unitSetting/search',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.unitList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            searchWarehouseStock(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/replaceWarehouseBill/searchWarehouseStock',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    (response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200) {
                        this.specification = response.data.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            //保存出库单
            onSubmit() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let url = '';
                if (this.isAddBill) {
                    url = '/admin/replaceWarehouseBill/saveVue';
               } else {
                    url = '/admin/replaceWarehouseBill/updateVue';
               }
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.showCustomerEdit = false;
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.$router.push({path: "/replaceOutWarehouse", query: {bill_id: this.formData.bill_id}})
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });
           }, searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/replaceWarehouseBill/search',
                    params: {
                        bill_id: this.formData.bill_id,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.showCustomerEdit = false;
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    loading.close();
               });
           },

       },
        created() {
            this.$_searchCategoryList(''),
            this.$_searcWarehouseList('');
            //this.$_searchWarehouseStock('');
            this.$_searchUnitList('');
            //this.$_searchAccountList('');
            this.$_getAccountListByRoleId('16');//经手人
            this.formData.only_bill_date = new Date();
            //出库单单号
            this.formData.bill_id = this.$route.query.bill_id;
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name;
           }
            var list = [];
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                this.tableDataMain = this.$_createRowData(list);
           } else {
                this.searchWarehouseBill();
           }

            this.loading = true
            this.tableDataMain = list;
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    /*    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend{
            width: 80px !important;
       }*/
    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }

    /*    .el-date-editor.el-input, .el-date-editor.el-input__inner{
            width: 128px !important;
       }
        .el-form-item__content .el-input-group{
            vertical-align: initial;
       }*/

</style>
